<!-- eslint-disable @typescript-eslint/no-unused-vars -->
<script setup lang="ts">
import indexHeaderNav from './components/DESKTOP/indexHeaderNav.vue';
import { RouterView, useRouter, useRoute } from 'vue-router';
import { useInfosUser } from './stores/userInfos';
import { useLogin } from './stores/login';
import { useEvaluation } from './stores/evaluation';
import { useRequestEvaluation } from './stores/requestEvaluation';
import { useHeaderInfos } from './stores/headerInfos';
import { useGroupTeamControl } from './stores/groupTeamControl';
import { useEvaluationView } from './stores/evaluationView';
import { useServices } from './stores/services';
import { useCompanyControl } from './stores/companyControl';
import { vehicle } from './stores/vehicle';
import { storeToRefs } from 'pinia';
import { OnClickOutside } from '@vueuse/components';
import { useToast } from 'vue-toastification';
import editaValorModal from './components/editaValorModal.vue';
import PWABadge from './components/pwaBadge.vue';
import editaValorModalDesk from './components/editaValorModalDesk.vue';
import editComentarioRenegociacaoModal from './components/editComentarioRenegociacaoModal.vue';
import editComentarioRenegociacaoModalDesk from './components/editComentarioRenegociacaoModalDesk.vue';
import historicoAlteracoesModal from './components/historicoAlteracoesModal.vue';
import desvioPagamentoModal from './components/desvioPagamentoModal.vue';
import desvioPagamentoModalDesktop from './components/desvioPagamentoModalDesktop.vue';
import personalizarNotificacoesDesk from './components/personalizarNotificacoesDesk.vue';
import toastText from './components/toastText.vue';
import insucessoIcon from './assets/icons/insucesso.vue';
import modalSolicitaAval from '../src/components/modalSolicitaAval.vue';
import modalSolicitaAvalDesk from '../src/components/modalSolicitaAvalDesk.vue';
import modalAvaliaSolicitacao from '../src/components/modalAvaliaSolicitacao.vue';
import modalAvaliaSolicitacaoDesk from '../src/components/modalAvaliaSolicitacaoDesk.vue';
import modalNotification from '../src/components/modalNotification.vue';
import modalNotificationDesk from '../src/components/modalNotificationDesk.vue';
import { ref, watch } from 'vue';
import axios from 'axios';
const servicesInfo = useServices();
const infosUserStore = useInfosUser();
const companyControl = useCompanyControl();
const evaluationView = useEvaluationView();
const headerInfos = useHeaderInfos();
const infosLogin = useLogin();
const evaluation = useEvaluation();
const requestEvalution = useRequestEvaluation();
const groupTeamControl = useGroupTeamControl();
const useVehicle = vehicle();
const { abertoMenuStart } = storeToRefs(headerInfos);
const { conviteVerified } = storeToRefs(useVehicle);
const { modalShowTabGroupTeam } = storeToRefs(groupTeamControl);
const { login, nome, sobrenome, email, cargo, grupo, equipe, empresa, id_cargo, id_equipe, id_grupo, id_empresa, idUsuario, acessos, modalNotificationOpen, modalDesvioPagamento, modalNotificacoes, infosSearch } = storeToRefs(infosLogin);
const { convite, toTop, conviteValue } = storeToRefs(evaluation);
const { users, groupsTeams, avaliacao, integracao, veiculo, regraDestino, regraPrecificacao, avaliacoes, solicitarAvaliacoes, listaSolicitacoesPendentes, autoPay, personalizarNotificacoes } = storeToRefs(servicesInfo);
const { desktop, mobile, screenWidth, screenHeight, routeUser } = storeToRefs(infosUserStore);
const { modelSolicitaOpen, modalAvaliaSolicitacaoOpen } = storeToRefs(requestEvalution);
const { modalEditaValor, tipoEdita, tituloEdita, modalHistorico, modalComentarioRenegociacao } = storeToRefs(evaluationView);
const { refreshListCompany, modalAddCompany, modalEditCompany, companyId, editCompanyName, clickButtonCompanyControl, companyUf, companyUfId, companyDropdownUfs } = storeToRefs(companyControl);

const router = useRouter();
const route = useRoute();
const toast = useToast();

watch(router.currentRoute, (value, oldValue) => {
	routeUser.value = value.name;
	if (value !== oldValue) {
		abertoMenuStart.value = false;
	}
});
watch(abertoMenuStart, (res) => {
	if (res === true) {
		const appElement = document.getElementById('app');
		if (appElement) {
			window.scrollTo(0, 0);
			appElement.style.overflow = 'hidden';
		} else {
			console.error('Elemento com ID "app" não encontrado');
		}
	} else {
		setTimeout(() => {
			const appElement = document.getElementById('app');
			if (appElement) {
				appElement.style.overflow = 'auto';
			}
		}, 300);
	}
});
watch(modalAvaliaSolicitacaoOpen, (res) => {
	if (res == true) {
		window.scrollTo(0, 0);
		document.getElementById('app').style.overflow = 'hidden';
	} else {
		setTimeout(() => {
			document.getElementById('app').style.overflow = 'auto';
		}, 300);
	}
});
watch(modelSolicitaOpen, (res) => {
	if (res == true) {
		window.scrollTo(0, 0);
		document.getElementById('app').style.overflow = 'hidden';
	} else {
		setTimeout(() => {
			document.getElementById('app').style.overflow = 'auto';
		}, 300);
	}
});
watch(modalNotificationOpen, (res) => {
	if (res == true) {
		window.scrollTo(0, 0);
		document.getElementById('app').style.overflow = 'hidden';
	} else {
		setTimeout(() => {
			document.getElementById('app').style.overflow = 'auto';
		}, 300);
	}
});
let previousScrollPosition = 0;

watch(modalEditaValor, (res) => {
	if (res === true) {
		document.getElementById('app').style.overflow = 'hidden';
	} else {
		setTimeout(() => {
			document.getElementById('app').style.overflow = 'auto';
		}, 300);
	}
});

watch(modalHistorico, (res) => {
	if (res === true) {
		document.getElementById('app').style.overflow = 'hidden';
	} else {
		setTimeout(() => {
			document.getElementById('app').style.overflow = 'auto';
		}, 300);
	}
});
watch(modalComentarioRenegociacao, (res) => {
	if (res === true) {
		document.getElementById('app').style.overflow = 'hidden';
	} else {
		setTimeout(() => {
			document.getElementById('app').style.overflow = 'auto';
		}, 300);
	}
});
watch(modalDesvioPagamento, (res) => {
	if (res === true) {
		document.getElementById('app').style.overflow = 'hidden';
	} else {
		setTimeout(() => {
			document.getElementById('app').style.overflow = 'auto';
		}, 300);
	}
});
watch(modalNotificacoes, (res) => {
	if (res === true) {
		document.getElementById('app').style.overflow = 'hidden';
	} else {
		setTimeout(() => {
			document.getElementById('app').style.overflow = 'auto';
		}, 300);
	}
});
watch(modalAddCompany, (res) => {
	if (res === true) {
		document.getElementById('app').style.overflow = 'hidden';
	} else {
		setTimeout(() => {
			document.getElementById('app').style.overflow = 'auto';
		}, 300);
	}
});
watch(modalEditCompany, (res) => {
	if (res === true) {
		document.getElementById('app').style.overflow = 'hidden';
	} else {
		setTimeout(() => {
			document.getElementById('app').style.overflow = 'auto';
		}, 300);
	}
});
watch(toTop, (res) => {
	if (res == true) {
		window.scrollTo(0, 0);
		document.getElementById('app').style.overflow = 'hidden';
		setTimeout(() => {
			document.getElementById('app').style.overflow = 'auto';
			toTop.value = false;
		}, 300);
	} else {
		setTimeout(() => {
			document.getElementById('app').style.overflow = 'auto';
			toTop.value = false;
		}, 300);
	}
});
const closeModalGroup = () => {
	modalShowTabGroupTeam.value = false;
};
const openEditGroup = () => {
	router.push({ name: 'controleGrupo' }).then(() => {
		closeModalGroup();
	});
};
const openEditTeam = () => {
	router.push({ name: 'controleEquipe' }).then(() => {
		closeModalGroup();
	});
};
let isFirstRoute = true;
router.beforeEach((to, from, next) => {
	if (isFirstRoute) {
		isFirstRoute = false;
		routeUser.value = to.path;
	}
	next();
});
function checkCookie() {
	var cookies = document.cookie.split(';');
	for (var i = 0; i < cookies.length; i++) {
		var cookie = cookies[i].trim();
		if (cookie.indexOf('token_user=') === 0) {
			return true;
		}
	}
	return false;
}
function setVerified() {
	if (checkCookie()) {
		conviteVerified.value = true;
	} else {
		setTimeout(setVerified, 100);
	}
}
function atualizarAtivos(array: any) {
	for (let i = 0; i < array.length; i++) {
		const idAcesso = array[i];

		const acesso = acessos.value.find((acesso: any) => acesso.id_acesso === idAcesso);
		if (acesso && acesso.ativo === 1) {
			for (let j = 0; j <= i; j++) {
				const idAcessoAnterior = array[j];
				const acessoAnterior = acessos.value.find((acesso: any) => acesso.id_acesso === idAcessoAnterior);
				if (acessoAnterior) {
					acessoAnterior.ativo = 1;
				}
			}
		}
	}
}
setTimeout(() => {
	const cookies = document.cookie.split(';');
	const tokenIndex = cookies.findIndex((cookie) => cookie.startsWith('token_user=') || cookie.startsWith(' token_user='));
	if (tokenIndex !== -1) {
		const tokenUser = cookies[tokenIndex].split('=')[1];
		if (routeUser.value == 'novaAvaliacao') {
			if (desktop.value == false) {
				if (route.query.token !== undefined && route.query.token !== null) {
					axios
						.get(`${import.meta.env.VITE_AUTO_ME_AVALIACOES}/avaliacoes/validaConvite`, {
							headers: {
								token: route.query.token.toString(),
								'Content-Type': 'application/json'
							}
						})
						.then((res) => {
							if (route.query.token) {
								conviteValue.value = route.query.token.toString();
							}
							empresa.value = res.data.dados.empresa.empresa;
							id_empresa.value = res.data.dados.empresa.id_empresa;
							equipe.value = res.data.dados.equipe.equipe;
							id_equipe.value = res.data.dados.equipe.id_equipe;
							grupo.value = res.data.dados.grupo.grupo;
							id_grupo.value = res.data.dados.grupo.id_grupo;
							idUsuario.value = res.data.dados.usuario.id_usuario;
							nome.value = res.data.dados.usuario.nome;
							sobrenome.value = res.data.dados.usuario.sobrenome;
							convite.value = true;
							infosSearch.value = true;
							axios
								.post(`${import.meta.env.VITE_AUTO_ME_USUARIO}/codificarBody`, {
									usuario: {
										id_usuario: idUsuario.value,
										nome: nome.value,
										sobrenome: sobrenome.value
									},
									grupo: {
										id_grupo: id_grupo.value,
										grupo: grupo.value
									},
									equipe: {
										id_equipe: id_equipe.value,
										equipe: equipe.value
									},
									empresa: {
										id_empresa: id_empresa.value,
										empresa: empresa.value
									}
								})
								.then((res) => {
									var data = new Date();
									data.setTime(data.getTime() + 24 * 60 * 60 * 1000);
									data.setUTCHours(data.getHours());
									data.setUTCMinutes(data.getMinutes());
									data.setUTCSeconds(data.getSeconds());
									var expires = 'expires=' + data.toUTCString();
									document.cookie = `token_user=${res.data.dados}; ` + expires + '; path=/';
									setVerified();
								})
								.catch((err) => {
									console.log(err);
								});
						})
						.catch((err) => {
							infosSearch.value = true;
							router.push('/');
							toast.error(
								{
									component: toastText,
									props: {
										h1Text: 'Deslogado',
										pText: err.response.data.mensagem
									}
								},
								{
									timeout: 5000,
									closeOnClick: false,
									pauseOnFocusLoss: false,
									pauseOnHover: false,
									draggable: true,
									draggablePercent: 0.8,
									showCloseButtonOnHover: false,
									hideProgressBar: false,
									closeButton: false,
									icon: insucessoIcon,
									rtl: false
								}
							);
						});
				} else {
					axios
						.post(
							`${import.meta.env.VITE_AUTO_ME_USUARIO}/decodificarHeader`,
							{},
							{
								headers: {
									Authorization: 'Bearer ' + tokenUser,
									'Content-Type': 'application/json'
								}
							}
						)
						.then((res) => {
							axios
								.post(
									`${import.meta.env.VITE_AUTO_ME_USUARIO}/usuarios/infoUsuario`,
									{
										id_usuario: res.data.dados.usuario.id_usuario
									},
									{
										headers: {
											Authorization: 'Bearer ' + tokenUser,
											'Content-Type': 'application/json'
										}
									}
								)
								.then((res) => {
									setVerified();
									idUsuario.value = res.data.dados.id_usuario;
									nome.value = res.data.dados.nome;
									acessos.value = res.data.dados.usuarios_acessos;
									let visualizacaoAvaliacoes = [100, 101, 102];
									let precificacaoAvaliacoes = [201, 202, 203];
									let solicitacaoAvaliacoes = [300, 301, 302];
									atualizarAtivos(visualizacaoAvaliacoes);
									atualizarAtivos(precificacaoAvaliacoes);
									atualizarAtivos(solicitacaoAvaliacoes);
									sobrenome.value = res.data.dados.sobrenome;
									email.value = res.data.dados.email;
									cargo.value = res.data.dados.cargo.cargo;
									grupo.value = res.data.dados.grupo.grupo;
									equipe.value = res.data.dados.grupo.equipe.equipe;
									empresa.value = res.data.dados.grupo.equipe.empresa.empresa;
									id_cargo.value = res.data.dados.id_cargo;
									id_equipe.value = res.data.dados.grupo.id_equipe;
									id_grupo.value = res.data.dados.id_grupo;
									id_empresa.value = res.data.dados.grupo.equipe.empresa.id_empresa;
									infosSearch.value = true;
									if (routeUser.value !== 'novaAvaliacao' && !(routeUser.value.startsWith('visualizarAvaliacao') || routeUser.value.startsWith('/visualizarAvaliacao'))) {
										router.push({ name: 'inicio' });
									}
								})
								.catch((err) => {
									if (err.message == 'Network Error') {
										if (routeUser.value !== 'recuperarSenha' && routeUser.value !== 'inicio' && routeUser.value !== 'recuperacaoSenha' && routeUser.value !== '404') {
											infosSearch.value = true;
											infosLogin.$reset();
											router.push('/');
											toast.error(
												{
													component: toastText,
													props: {
														h1Text: 'Serviço indisponível',
														pText: 'serviço passando por instabilidade'
													}
												},
												{
													timeout: 8000,
													closeOnClick: false,
													pauseOnFocusLoss: false,
													pauseOnHover: false,
													draggable: true,
													draggablePercent: 0.8,
													showCloseButtonOnHover: false,
													hideProgressBar: false,
													closeButton: false,
													icon: insucessoIcon,
													rtl: false
												}
											);
										}
									} else {
										if (err.response.data.error == 'Token invalid.' || err.response.data.error == 'Token malformatted.' || err.response.data.error == 'Token error.' || err.response.data.error == 'No token provided.') {
											if (routeUser.value !== 'recuperarSenha' && routeUser.value !== 'inicio' && routeUser.value !== 'recuperacaoSenha' && routeUser.value !== '404') {
												infosLogin.$reset();
												router.push('/');
												toast.error(
													{
														component: toastText,
														props: {
															h1Text: 'Deslogado',
															pText: 'Sua sessão expirou'
														}
													},
													{
														timeout: 5000,
														closeOnClick: false,
														pauseOnFocusLoss: false,
														pauseOnHover: false,
														draggable: true,
														draggablePercent: 0.8,
														showCloseButtonOnHover: false,
														hideProgressBar: false,
														closeButton: false,
														icon: insucessoIcon,
														rtl: false
													}
												);
											}
										}
									}
								});
						})
						.catch((err) => {
							console.log(err);
						});
				}
			} else {
				if (route.query.token !== undefined && route.query.token !== null) {
					router.push('/404');
				} else {
					axios
						.post(
							`${import.meta.env.VITE_AUTO_ME_USUARIO}/decodificarHeader`,
							{},
							{
								headers: {
									Authorization: 'Bearer ' + tokenUser,
									'Content-Type': 'application/json'
								}
							}
						)
						.then((res) => {
							axios
								.post(
									`${import.meta.env.VITE_AUTO_ME_USUARIO}/usuarios/infoUsuario`,
									{
										id_usuario: res.data.dados.usuario.id_usuario
									},
									{
										headers: {
											Authorization: 'Bearer ' + tokenUser,
											'Content-Type': 'application/json'
										}
									}
								)
								.then((res) => {
									setVerified();
									idUsuario.value = res.data.dados.id_usuario;
									nome.value = res.data.dados.nome;
									acessos.value = res.data.dados.usuarios_acessos;
									let visualizacaoAvaliacoes = [100, 101, 102];
									let precificacaoAvaliacoes = [201, 202, 203];
									let solicitacaoAvaliacoes = [300, 301, 302];
									atualizarAtivos(visualizacaoAvaliacoes);
									atualizarAtivos(precificacaoAvaliacoes);
									atualizarAtivos(solicitacaoAvaliacoes);
									sobrenome.value = res.data.dados.sobrenome;
									email.value = res.data.dados.email;
									cargo.value = res.data.dados.cargo.cargo;
									grupo.value = res.data.dados.grupo.grupo;
									equipe.value = res.data.dados.grupo.equipe.equipe;
									empresa.value = res.data.dados.grupo.equipe.empresa.empresa;
									id_cargo.value = res.data.dados.id_cargo;
									id_equipe.value = res.data.dados.grupo.id_equipe;
									id_grupo.value = res.data.dados.id_grupo;
									id_empresa.value = res.data.dados.grupo.equipe.empresa.id_empresa;
									infosSearch.value = true;
									if (routeUser.value !== 'novaAvaliacao' && !(routeUser.value.startsWith('visualizarAvaliacao') || routeUser.value.startsWith('/visualizarAvaliacao'))) {
										router.push({ name: 'inicio' });
									}
								})
								.catch((err) => {
									if (err.message == 'Network Error') {
										if (routeUser.value !== 'recuperarSenha' && routeUser.value !== 'inicio' && routeUser.value !== 'recuperacaoSenha' && routeUser.value !== '404') {
											infosSearch.value = true;
											infosLogin.$reset();
											router.push('/');
											toast.error(
												{
													component: toastText,
													props: {
														h1Text: 'Serviço indisponível',
														pText: 'serviço passando por instabilidade'
													}
												},
												{
													timeout: 8000,
													closeOnClick: false,
													pauseOnFocusLoss: false,
													pauseOnHover: false,
													draggable: true,
													draggablePercent: 0.8,
													showCloseButtonOnHover: false,
													hideProgressBar: false,
													closeButton: false,
													icon: insucessoIcon,
													rtl: false
												}
											);
										}
									} else {
										if (err.response.data.error == 'Token invalid.' || err.response.data.error == 'Token malformatted.' || err.response.data.error == 'Token error.' || err.response.data.error == 'No token provided.') {
											if (routeUser.value !== 'recuperarSenha' && routeUser.value !== 'inicio' && routeUser.value !== 'recuperacaoSenha' && routeUser.value !== '404') {
												infosSearch.value = true;
												infosLogin.$reset();
												router.push('/');
												toast.error(
													{
														component: toastText,
														props: {
															h1Text: 'Deslogado',
															pText: 'Sua sessão expirou'
														}
													},
													{
														timeout: 5000,
														closeOnClick: false,
														pauseOnFocusLoss: false,
														pauseOnHover: false,
														draggable: true,
														draggablePercent: 0.8,
														showCloseButtonOnHover: false,
														hideProgressBar: false,
														closeButton: false,
														icon: insucessoIcon,
														rtl: false
													}
												);
											}
										}
									}
								});
						})
						.catch((err) => {
							console.log(err);
						});
				}
			}
		} else {
			if (routeUser.value !== 'recuperacaoSenha') {
				axios
					.post(
						`${import.meta.env.VITE_AUTO_ME_USUARIO}/decodificarHeader`,
						{},
						{
							headers: {
								Authorization: 'Bearer ' + tokenUser,
								'Content-Type': 'application/json'
							}
						}
					)
					.then((res) => {
						axios
							.post(
								`${import.meta.env.VITE_AUTO_ME_USUARIO}/usuarios/infoUsuario`,
								{
									id_usuario: res.data.dados.usuario.id_usuario
								},
								{
									headers: {
										Authorization: 'Bearer ' + tokenUser,
										'Content-Type': 'application/json'
									}
								}
							)
							.then((res) => {
								idUsuario.value = res.data.dados.id_usuario;
								nome.value = res.data.dados.nome;
								acessos.value = res.data.dados.usuarios_acessos;
								let visualizacaoAvaliacoes = [100, 101, 102];
								let precificacaoAvaliacoes = [201, 202, 203];
								let solicitacaoAvaliacoes = [300, 301, 302];
								atualizarAtivos(visualizacaoAvaliacoes);
								atualizarAtivos(precificacaoAvaliacoes);
								atualizarAtivos(solicitacaoAvaliacoes);
								sobrenome.value = res.data.dados.sobrenome;
								email.value = res.data.dados.email;
								cargo.value = res.data.dados.cargo.cargo;
								grupo.value = res.data.dados.grupo.grupo;
								equipe.value = res.data.dados.grupo.equipe.equipe;
								empresa.value = res.data.dados.grupo.equipe.empresa.empresa;
								id_cargo.value = res.data.dados.id_cargo;
								id_equipe.value = res.data.dados.grupo.id_equipe;
								id_grupo.value = res.data.dados.id_grupo;
								id_empresa.value = res.data.dados.grupo.equipe.empresa.id_empresa;
								infosSearch.value = true;
								setVerified();
								if (routeUser.value !== 'novaAvaliacao' && !(routeUser.value.startsWith('visualizarAvaliacao') || routeUser.value.startsWith('/visualizarAvaliacao'))) {
									router.push({ name: 'inicio' });
								}
							})
							.catch((err) => {
								if (err.message == 'Network Error') {
									if (routeUser.value !== 'recuperarSenha' && routeUser.value !== 'inicio' && routeUser.value !== 'recuperacaoSenha' && routeUser.value !== '404') {
										infosSearch.value = true;
										infosLogin.$reset();
										router.push('/');
										toast.error(
											{
												component: toastText,
												props: {
													h1Text: 'Serviço indisponível',
													pText: 'serviço passando por instabilidade'
												}
											},
											{
												timeout: 8000,
												closeOnClick: false,
												pauseOnFocusLoss: false,
												pauseOnHover: false,
												draggable: true,
												draggablePercent: 0.8,
												showCloseButtonOnHover: false,
												hideProgressBar: false,
												closeButton: false,
												icon: insucessoIcon,
												rtl: false
											}
										);
									}
								} else {
									if (err.response.data.error == 'Token invalid.' || err.response.data.error == 'Token malformatted.' || err.response.data.error == 'Token error.' || err.response.data.error == 'No token provided.') {
										if (routeUser.value !== 'recuperarSenha' && routeUser.value !== 'inicio' && routeUser.value !== 'recuperacaoSenha' && routeUser.value !== '404') {
											infosSearch.value = true;
											infosLogin.$reset();
											router.push('/');
											toast.error(
												{
													component: toastText,
													props: {
														h1Text: 'Deslogado',
														pText: 'Sua sessão expirou'
													}
												},
												{
													timeout: 5000,
													closeOnClick: false,
													pauseOnFocusLoss: false,
													pauseOnHover: false,
													draggable: true,
													draggablePercent: 0.8,
													showCloseButtonOnHover: false,
													hideProgressBar: false,
													closeButton: false,
													icon: insucessoIcon,
													rtl: false
												}
											);
										}
									}
								}
							});
					})
					.catch((err) => {
						console.log(err);
					});
			} else {
				// pass
			}
		}
	} else {
		if (routeUser.value == 'novaAvaliacao' || routeUser.value == '/novaAvaliacao') {
			if (desktop.value == false) {
				if (route.query.token !== undefined && route.query.token !== null) {
					axios
						.get(`${import.meta.env.VITE_AUTO_ME_AVALIACOES}/avaliacoes/validaConvite`, {
							headers: {
								token: route.query.token.toString(),
								'Content-Type': 'application/json'
							}
						})
						.then((res) => {
							if (route.query.token) {
								conviteValue.value = route.query.token.toString();
							}
							empresa.value = res.data.dados.empresa.empresa;
							id_empresa.value = res.data.dados.empresa.id_empresa;
							equipe.value = res.data.dados.equipe.equipe;
							id_equipe.value = res.data.dados.equipe.id_equipe;
							grupo.value = res.data.dados.grupo.grupo;
							id_grupo.value = res.data.dados.grupo.id_grupo;
							idUsuario.value = res.data.dados.usuario.id_usuario;
							nome.value = res.data.dados.usuario.nome;
							sobrenome.value = res.data.dados.usuario.sobrenome;
							convite.value = true;
							infosSearch.value = true;
							axios
								.post(`${import.meta.env.VITE_AUTO_ME_USUARIO}/codificarBody`, {
									usuario: {
										id_usuario: idUsuario.value,
										nome: nome.value,
										sobrenome: sobrenome.value
									},
									grupo: {
										id_grupo: id_grupo.value,
										grupo: grupo.value
									},
									equipe: {
										id_equipe: id_equipe.value,
										equipe: equipe.value
									},
									empresa: {
										id_empresa: id_empresa.value,
										empresa: empresa.value
									}
								})
								.then((res) => {
									var data = new Date();
									data.setTime(data.getTime() + 24 * 60 * 60 * 1000);
									data.setUTCHours(data.getHours());
									data.setUTCMinutes(data.getMinutes());
									data.setUTCSeconds(data.getSeconds());
									var expires = 'expires=' + data.toUTCString();
									document.cookie = `token_user=${res.data.dados}; ` + expires + '; path=/';
								})
								.catch((err) => {
									console.log(err);
								});
						})
						.catch((err) => {
							infosSearch.value = true;
							router.push('/');
							toast.error(
								{
									component: toastText,
									props: {
										h1Text: 'Deslogado',
										pText: err.response.data.mensagem
									}
								},
								{
									timeout: 5000,
									closeOnClick: false,
									pauseOnFocusLoss: false,
									pauseOnHover: false,
									draggable: true,
									draggablePercent: 0.8,
									showCloseButtonOnHover: false,
									hideProgressBar: false,
									closeButton: false,
									icon: insucessoIcon,
									rtl: false
								}
							);
						});
				} else {
					infosSearch.value = true;
					router.push('/');
					toast.error(
						{
							component: toastText,
							props: {
								h1Text: 'Deslogado',
								pText: 'Convite não permitido'
							}
						},
						{
							timeout: 5000,
							closeOnClick: false,
							pauseOnFocusLoss: false,
							pauseOnHover: false,
							draggable: true,
							draggablePercent: 0.8,
							showCloseButtonOnHover: false,
							hideProgressBar: false,
							closeButton: false,
							icon: insucessoIcon,
							rtl: false
						}
					);
				}
			} else {
				router.push('/404');
			}
		} else {
			if (routeUser.value !== 'recuperarSenha' && routeUser.value !== 'login' && routeUser.value !== 'recuperacaoSenha' && routeUser.value !== '404') {
				infosSearch.value = true;
				router.push('/');
				toast.error(
					{
						component: toastText,
						props: {
							h1Text: 'Deslogado',
							pText: 'Sua sessão expirou'
						}
					},
					{
						timeout: 5000,
						closeOnClick: false,
						pauseOnFocusLoss: false,
						pauseOnHover: false,
						draggable: true,
						draggablePercent: 0.8,
						showCloseButtonOnHover: false,
						hideProgressBar: false,
						closeButton: false,
						icon: insucessoIcon,
						rtl: false
					}
				);
			}
		}
	}
}, 1200);

window.addEventListener('resize', handleResize);
function handleResize() {
	screenWidth.value = window.innerWidth;
	screenHeight.value = window.innerHeight;
	if (window.innerWidth < 768) {
		// this.isDesktop = false;
		desktop.value = false;
		mobile.value = true;
	} else {
		// this.isDesktop = true;
		desktop.value = true;
		mobile.value = false;
	}
	let vh = window.innerHeight * 0.01;
	document.documentElement.style.setProperty('--vh', `${vh}px`);
}
handleResize();
</script>

<template>
	<TransitionGroup name="modalEnter">
		<div class="bg" v-if="modalShowTabGroupTeam && desktop" style="justify-content: flex-start; padding: 142px 235px; align-items: flex-start">
			<OnClickOutside @trigger="closeModalGroup()" class="clickOutside">
				<div class="gruposEquipesTab">
					<div class="tabGroup" @click="openEditTeam()">
						<div class="tabContainer">
							<div class="tabContent">
								<div class="icon">
									<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M11 8H21V20H11M11 8V4H3V20H11M11 8V20" stroke="#121F40" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
										<path d="M7 4V16M7 20V16M7 16H3M7 16H13M13 12H3M11 8H3M16.5 12H17.5M16.5 16H17.5" stroke="#121F40" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
									</svg>
								</div>
								<div class="label">
									<h1>Equipes</h1>
									<p>adicionar, apagar, e editar equipes</p>
								</div>
							</div>
						</div>
					</div>
					<div class="tabTeam" @click="openEditGroup()">
						<div class="tabContainer">
							<div class="tabContent">
								<div class="icon">
									<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path fill-rule="evenodd" clip-rule="evenodd" d="M15 8C15 9.65675 13.6568 11 12 11C10.3432 11 9 9.65675 9 8C9 6.34325 10.3432 5 12 5C13.6568 5 15 6.34325 15 8Z" stroke="#121F40" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
										<path fill-rule="evenodd" clip-rule="evenodd" d="M11 15C11 16.6568 9.65675 18 8 18C6.34325 18 5 16.6568 5 15C5 13.3432 6.34325 12 8 12C9.65675 12 11 13.3432 11 15Z" stroke="#121F40" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
										<path fill-rule="evenodd" clip-rule="evenodd" d="M19 15C19 16.6568 17.6568 18 16 18C14.3432 18 13 16.6568 13 15C13 13.3432 14.3432 12 16 12C17.6568 12 19 13.3432 19 15Z" stroke="#121F40" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
									</svg>
								</div>
								<div class="label">
									<h1>Grupos</h1>
									<p>editar uf de preferência, e editar grupos</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</OnClickOutside>
		</div>
	</TransitionGroup>
	<TransitionGroup name="modalEnter">
		<div class="bg" v-if="modelSolicitaOpen">
			<modalSolicitaAval v-if="mobile" />
			<modalSolicitaAvalDesk v-else />
		</div>
	</TransitionGroup>
	<TransitionGroup name="modalEnter">
		<div class="bg" v-if="modalNotificacoes">
			<personalizarNotificacoesDesk />
		</div>
	</TransitionGroup>
	<TransitionGroup name="modalEnter">
		<div class="bg" v-if="modalDesvioPagamento">
			<desvioPagamentoModal :type="tipoEdita" :title="tituloEdita" v-if="mobile" />
			<desvioPagamentoModalDesktop :type="tipoEdita" :title="tituloEdita" v-else />
		</div>
	</TransitionGroup>
	<TransitionGroup name="modalEnter">
		<div class="bg" v-if="modalEditaValor">
			<editaValorModal :type="tipoEdita" :title="tituloEdita" v-if="mobile" />
			<editaValorModalDesk :type="tipoEdita" :title="tituloEdita" v-else />
		</div>
	</TransitionGroup>
	<TransitionGroup name="modalEnter">
		<div class="bg" v-if="modalHistorico">
			<historicoAlteracoesModal />
		</div>
	</TransitionGroup>
	<TransitionGroup name="modalEnter">
		<div class="bg" v-if="modalComentarioRenegociacao">
			<editComentarioRenegociacaoModal v-if="mobile" />
			<editComentarioRenegociacaoModalDesk v-else />
		</div>
	</TransitionGroup>
	<TransitionGroup name="modalEnter">
		<div class="bg" v-if="modalNotificationOpen">
			<modalNotification v-if="mobile" />
			<modalNotificationDesk v-else />
		</div>
	</TransitionGroup>
	<TransitionGroup name="modalEnter">
		<div class="bg" v-if="modalAvaliaSolicitacaoOpen">
			<modalAvaliaSolicitacao v-if="mobile" />
			<modalAvaliaSolicitacaoDesk v-if="desktop" />
		</div>
	</TransitionGroup>
	<indexHeaderNav v-if="desktop && routeUser !== 'login' && routeUser !== 'recuperarSenha' && routeUser !== 'recuperacaoSenha' && routeUser !== '404'" />
	<router-view v-slot="{ Component, route }">
		<transition name="switchRoutes" :mode="'out-in'">
			<component :is="Component" :key="route.path" />
		</transition>
		<PWABadge />
	</router-view>
</template>
