import { createRouter, createWebHistory } from 'vue-router';
import login from '../views/login.vue';

const router = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	routes: [
		{
			path: '/',
			name: 'login',
			component: login
		},
		{
			path: '/recuperarSenha',
			name: 'recuperarSenha',
			component: () => import('../views/recuperarSenha.vue')
		},
		{
			path: '/recuperacaoSenha',
			name: 'recuperacaoSenha',
			component: () => import('../views/recuperacaoDeSenha.vue'),
			props: (route) => ({
				email: route.query.email,
				passwordToken: route.query.passwordToken,
				passwordExpireDate: route.query.passwordExpireDate
			})
		},
		{
			path: '/inicio',
			name: 'inicio',
			component: () => import('../views/inicio.vue')
		},
		{
			path: '/meuPerfil',
			name: 'meuPerfil',
			component: () => import('../views/meuPerfil.vue')
		},
		{
			path: '/alterarSenha',
			name: 'alterarSenha',
			component: () => import('../views/alterarSenha.vue')
		},
		{
			path: '/integracao',
			name: 'integracao',
			component: () => import('../views/integracao.vue')
		},
		{
			path: '/regrasDestino',
			name: 'regrasDestino',
			component: () => import('../views/regrasDestino.vue')
		},
		{
			path: '/regrasPrecificacao',
			name: 'regrasPrecificacao',
			component: () => import('../views/regrasPrecificacao.vue')
		},
		{
			path: '/controleUsuario',
			name: 'controleUsuario',
			component: () => import('../views/controleUsuario.vue')
		},
		{
			path: '/controleUsuarioAcessos',
			name: 'controleUsuarioAcessos',
			component: () => import('../views/controleUsuarioAcessos.vue')
		},
		{
			path: '/gruposEquipes',
			name: 'gruposEquipes',
			component: () => import('../views/gruposEquipes.vue')
		},
		{
			path: '/controleGrupo',
			name: 'controleGrupo',
			component: () => import('../views/controleGrupo.vue')
		},
		{
			path: '/controleEquipe',
			name: 'controleEquipe',
			component: () => import('../views/controleEquipe.vue')
		},
		{
			path: '/controleEmpresa',
			name: 'controleEmpresa',
			component: () => import('../views/controleEmpresa.vue')
		},
		{
			path: '/novaAvaliacao',
			name: 'novaAvaliacao',
			component: () => import('../views/novaAvaliacao.vue'),
			props: (route) => {
				route.query.token;
			}
		},
		{
			path: '/avaliacao',
			name: 'avaliacao',
			component: () => import('../views/avaliacao.vue')
		},
		{
			path: '/avaliacoes',
			name: 'avaliacoes',
			component: () => import('../views/avaliacoes.vue')
		},
		{
			path: '/v2/reviews',
			name: 'ReviewsPagev2',
			component: () => import('../views/v2/ReviewsPage.vue')
		},
		{
			path: '/visualizarAvaliacao/:id_avaliacao',
			name: 'visualizarAvaliacao',
			component: () => import('../views/visualizarAvaliacao.vue')
		},
		{
			path: '/listaSolicitacoes',
			name: 'listaSolicitacoes',
			component: () => import('../views/solicitacoesPendentes.vue')
		},
		{
			path: '/autoPay',
			name: 'autoPay',
			component: () => import('../views/autoPay.vue')
		},
		{
			path: '/personalizarNotificacoes',
			name: 'personalizarNotificacoes',
			component: () => import('../views/personalizarNotificacoes.vue')
		},
		{
			path: '/relatorios',
			name: 'relatorios',
			component: () => import('../views/relatorios.vue')
		},
		{
			path: '/404',
			name: '404',
			component: () => import('../views/404.vue')
		}
	]
});

export default router;
