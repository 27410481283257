import { defineStore } from 'pinia';

export const useGroupTeamControl = defineStore('controlGroupTeam', {
	state: () => ({
		modalAddGroup: false,
		modalEditGroup: false,
		modalDeleteGroup: false,
		modalAddTeam: false,
		modalEditTeam: false,
		modalDeleteTeam: false,
		modalShowTabGroupTeam: false,
		dropdownGroupE: false,
		dropdownTeam: false,
		dropdownEditGroupUfs: false,
		dropdownNewGroupUf: false,
		newGroupName: '',
		newTeamGroup: '',
		newGroupUfId: '',
		newGroupUf: '',
		newTeamGroupId: 0,
		editGroupName: '',
		editTeamName: '',
		editGroupUf: '',
		editGroupUfId: '',
		groupId: 0,
		teamId: 0,
		editTeam: '',
		deleteGroupPassword: '',
		clickButtonGroupTeamControl: false,
		errorGroupTeamControl: false,
		editGroupTeamIndexClicked: 0,
		errorTextGroupTeamControl: '',
		haveAErrorGroupTeamControl: false,
		refreshListGroups: false,
		refreshListTeams: false,
		errorTextGroupDropdownUfs: ''
	})
});
