import { defineStore } from 'pinia';

export const useEvaluationView = defineStore('evaluationView', {
	state: () => ({
		classificacao_metal: '' as null | string,
		avaliacao_rapida: false,
		id_avaliacao: null as number | null,
		precificado: false,
		eletricoView: false,
		preco: 0 as number,
		auto_pay: 0 as any,
		convidado: 0 as any,
		avaliacaoEscolhida: 0,
		comentario: '',
		status: '',
		id_status: 0,
		status_renegociacao: 0,
		pode_renegociar: false,
		errorEvaluationView: false,
		errorEvaluationViewText: '',
		veiculo_avaliado: {
			marca: '',
			modelo: '',
			versao: '',
			placa: '',
			blindagem: '',
			renavam: '',
			teto_solar: '',
			ano: '',
			id_fipe: '',
			portas: '',
			cor: '',
			carroceria: '',
			ar_condicionado: '',
			transmissao: '',
			data_avaliacao: '',
			combustivel: '',
			tipo_veiculo: {
				id_tipo_veiculo: '',
				tipo_veiculo: ''
			}
		},
		usuario: {
			id_usuario: null,
			nome: '',
			sobrenome: ''
		},
		grupo: {
			id_grupo: null,
			grupo: ''
		},
		equipe: {
			id_equipe: null,
			equipe: ''
		},
		avaliacao: {
			fotos: [] as any,
			km: 0,
			documento: 0,
			manual: '',
			chave_reserva: '',
			revisao: {
				feita: '',
				km: 0,
				data: ''
			},
			garantia_fabrica: {
				valida: '',
				data: ''
			},
			alteracoes_csv: [],
			tipo_negociacao: '',
			perguntas: [] as any,
			higienizacao_polimento: {
				respostas: '',
				valor: 0
			},
			outros_custos: {
				valor: 0,
				descricao: ''
			}
		},
		fipe: {
			fipe_atual: 0,
			variacao_ultimo_mes: 0,
			variacao_ultimo_mes_perc: 0,
			variacao_ultimos_seis_meses: 0,
			variacao_ultimos_seis_meses_perc: 0,
			fipes_historico_lista: []
		},
		precificacao: {
			auto_pay: null,
			precisao: '',
			internet: {
				media_internet: 0,
				internet_posicao_anuncio: null,
				internet_posicao_anuncio_perc: 0,
				internet_classificacao: '',
				internet_anuncios_ativos: 0,
				internet_anuncios_ativos_var: 0,
				internet_tempo_anuncios_ativos: 0,
				internet_tempo_anuncios_ativos_var: 0,
				internet_anuncios_removidos: 0,
				internet_anuncios_removidos_var: 0,
				internet_tempo_anuncios_removidos: 0,
				internet_tempo_anuncios_removidos_var: 0,
				internet_anuncios_removidos_mesma_classificacao: null,
				internet_tempo_medio_mesma_classificacao: null,
				internet_km_maxima: 0,
				internet_km_minima: 0,
				internet_valor_maximo: 0,
				internet_valor_minimo: 0,
				internet_tempo_medio_anuncio: null,
				anuncios: [
					{
						foto: '',
						ano_fabricacao: '',
						ano_modelo: '',
						cidade: '',
						id_anuncio: 0,
						km: 0,
						marca: '',
						modelo: '',
						uf: '',
						valor: 0,
						versao: ''
					}
				]
			},
			veiculo_tipos: {
				id_veiculo_tipo: null,
				veiculo_tipo: ''
			},
			metais: {
				id_metal: null,
				metal: ''
			},
			avaliacoes: [
				{
					destino: {
						id_destino: null,
						destino: ''
					},
					praca: '',
					media_internet: 0,
					margem_negociacao: 0,
					lucro: 0,
					reparos: 0,
					higienizacao_estetica: 0,
					depreciacao_capital: 0,
					depreciacao_fipe: 0,
					ipva: 0,
					provisao_revisoes: 0,
					icms: 0,
					pis_cofins: 0,
					valor_sugerido: 0,
					lucro_repassador: 0,
					outros_custos: 0
				}
			],
			destino: {
				destino: '',
				id_destino: 0
			}
		} as any,
		veiculo_tipos: {
			veiculo_tipo: '',
			id_tipo: 0
		},
		historico_alteracoes: {
			usuario: '',
			data_alteracao: '',
			alteracao: '',
			comentario: ''
		} as any,
		renegociacoes: {
			status_renegociacao: 0,
			destino: {
				id_destino: 0,
				destino: ''
			},
			media_internet: 0,
			internet_tempo_medio_anuncio: 0,
			margem_negociacao: 0,
			lucro: 0,
			reparos: 0,
			higienizacao_estetica: 0,
			depreciacao_capital: 0,
			depreciacao_fipe: 0,
			ipva: 0,
			provisao_revisoes: 0,
			icms: 0,
			pis_cofins: 0,
			valor_sugerido: 0,
			lucro_repassador: 0
		},
		modalEditaValor: false,
		tipoEdita: '',
		tituloEdita: '',
		editValorFinal: '',
		errorValorFinal: false,
		errorTextValorFinal: '',
		clickedButtonEditValorFinal: false,
		modalHistorico: false,
		modalComentarioRenegociacao: false,
		valorTempoVendaNovo: '',
		valorMediaInternetNovo: '',
		valorMargemNegociacaoNovo: '',
		valorLucroNovo: '',
		valorReparosNovo: '',
		valorHigienizacaoEsteticaNovo: '',
		valorProvisaoRevisoesNovo: '',
		valorOutrosCustosNovo: '',
		historicoAvaliacaoData: '',
		historicoAvaliacaoGrupo: '',
		historicoAvaliacaoUsuario: '',
		historicoAvaliacaoValor: '',
		indexButton: 0,
		avaliacaoLoaded: false,
		loadingEvaluation: false,
		ignorarTetoSolar: false,
		idAnuncioIgnorar: '',
		ignorarAnuncio: [] as Array<number>,
		modalIgnorarTetoSolar: false,
		modalIgnorarAnuncio: false,
		clickedButtonEditRenegociacao: false,
		clickedAceitaRenegociacao: false,
		clickedRecusaRenegociacao: false,
		clickedVetaRenegociacao: false,
		clickedButtonAdquireVeiculo: false,
		clickedButtonPagaAvaliacao: false,
		typeComentario: '',
		comentarioRenegociacao: '',
		clickedButtonAdquirirAvaliacao: false,
		clickedButtonVenderVeiculo: false
	})
});
