interface ReviewsStatusConfig {
	/**
	 * Code: 1 - Awaiting evaluation board/Aguardando mesa avaliadora
	 */
	AWAITING_EVALUATION_BOARD: number;
	/**
	 * Code: 2 - Priced/Precificado
	 */
	PRICED: number;
	/**
	 * Code: 3 - Acquired/Adquirido
	 */
	ACQUIRED: number;
	/**
	 * Code: 4 - Sold/Vendido
	 */
	SOLD: number;
}

const REVIEWS_STATUS_CONFIG: ReviewsStatusConfig = {
	AWAITING_EVALUATION_BOARD: 1,
	PRICED: 2,
	ACQUIRED: 3,
	SOLD: 4
};

export default REVIEWS_STATUS_CONFIG;
