<script setup lang="ts">
import inputGroup from './inputGroup.vue';
import buttonVue from './MOBILE/buttons/button.vue';

import { ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useEvaluationView } from '../stores/evaluationView';
const viewEvaluation = useEvaluationView();
const { modalEditaValor, editValorFinal, errorValorFinal, errorTextValorFinal, valorTempoVendaNovo, valorHigienizacaoEsteticaNovo, valorLucroNovo, valorMargemNegociacaoNovo, valorMediaInternetNovo, valorProvisaoRevisoesNovo, valorReparosNovo, precificacao, indexButton, valorOutrosCustosNovo } = storeToRefs(viewEvaluation);

const props = defineProps<{
	type: string;
	title: string;
}>();

const clickOutside = () => {
	modalEditaValor.value = false;
	if (props.type == 'tempoVenda') {
		valorTempoVendaNovo.value = precificacao.value.internet.internet_tempo_anuncios_removidos;
	}
	if (props.type == 'mediaInternet') {
		valorMediaInternetNovo.value = precificacao.value.avaliacoes[indexButton.value].media_internet.toLocaleString('pt-BR');
	}
	if (props.type == 'margemNegociacao') {
		valorMargemNegociacaoNovo.value = precificacao.value.avaliacoes[indexButton.value].margem_negociacao.toLocaleString('pt-BR');
	}
	if (props.type == 'lucro') {
		valorLucroNovo.value = precificacao.value.avaliacoes[indexButton.value].lucro.toLocaleString('pt-BR');
	}
	if (props.type == 'reparos') {
		valorReparosNovo.value = precificacao.value.avaliacoes[indexButton.value].reparos.toLocaleString('pt-BR');
	}
	if (props.type == 'higienizacao') {
		valorHigienizacaoEsteticaNovo.value = precificacao.value.avaliacoes[indexButton.value].higienizacao_estetica.toLocaleString('pt-BR');
	}
	if (props.type == 'provisaoRevisoes') {
		valorProvisaoRevisoesNovo.value = precificacao.value.avaliacoes[indexButton.value].provisao_revisoes.toLocaleString('pt-BR');
	}
	if (props.type == 'outrosCustos') {
		valorOutrosCustosNovo.value = precificacao.value.avaliacoes[indexButton.value].outros_custos.toLocaleString('pt-BR');
	}
};
const closeModal = () => {
	modalEditaValor.value = false;
	if (props.type == 'tempoVenda') {
		valorTempoVendaNovo.value = precificacao.value.internet.internet_tempo_anuncios_removidos == null ? 0 : precificacao.value.internet.internet_tempo_anuncios_removidos;
	}
	if (props.type == 'mediaInternet') {
		valorMediaInternetNovo.value = precificacao.value.avaliacoes[indexButton.value].media_internet.toLocaleString('pt-BR');
	}
	if (props.type == 'margemNegociacao') {
		valorMargemNegociacaoNovo.value = precificacao.value.avaliacoes[indexButton.value].margem_negociacao.toLocaleString('pt-BR');
	}
	if (props.type == 'lucro') {
		valorLucroNovo.value = precificacao.value.avaliacoes[indexButton.value].lucro.toLocaleString('pt-BR');
	}
	if (props.type == 'reparos') {
		valorReparosNovo.value = precificacao.value.avaliacoes[indexButton.value].reparos.toLocaleString('pt-BR');
	}
	if (props.type == 'higienizacao') {
		valorHigienizacaoEsteticaNovo.value = precificacao.value.avaliacoes[indexButton.value].higienizacao_estetica.toLocaleString('pt-BR');
	}
	if (props.type == 'provisaoRevisoes') {
		valorProvisaoRevisoesNovo.value = precificacao.value.avaliacoes[indexButton.value].provisao_revisoes.toLocaleString('pt-BR');
	}
	if (props.type == 'outrosCustos') {
		valorOutrosCustosNovo.value = precificacao.value.avaliacoes[indexButton.value].outros_custos.toLocaleString('pt-BR');
	}
};
const buttonActive = ref(false);

watch(editValorFinal, (val) => {
	if (props.type == 'valorFinal') {
		if (parseFloat(val.replace(/[^\d,]/g, '').replace(',', '.')) > 0) {
			buttonActive.value = true;
		} else {
			buttonActive.value = false;
		}
	}
});

watch(
	valorTempoVendaNovo,
	(val) => {
		if (props.type == 'tempoVenda') {
			if (val.toString().length > 0) {
				buttonActive.value = true;
			} else {
				buttonActive.value = false;
			}
		}
	},
	{ immediate: true }
);

watch(
	valorMediaInternetNovo,
	(val) => {
		if (props.type == 'mediaInternet') {
			if (val.toString().length > 0) {
				buttonActive.value = true;
			} else {
				buttonActive.value = false;
			}
		}
	},
	{ immediate: true }
);

watch(
	valorMargemNegociacaoNovo,
	(val) => {
		if (props.type == 'margemNegociacao') {
			if (val.toString().length > 0) {
				buttonActive.value = true;
			} else {
				buttonActive.value = false;
			}
		}
	},
	{ immediate: true }
);

watch(
	valorLucroNovo,
	(val) => {
		if (props.type == 'lucro') {
			if (val.toString().length > 0) {
				buttonActive.value = true;
			} else {
				buttonActive.value = false;
			}
		}
	},
	{ immediate: true }
);

watch(
	valorReparosNovo,
	(val) => {
		if (props.type == 'reparos') {
			if (val.toString().length > 0) {
				buttonActive.value = true;
			} else {
				buttonActive.value = false;
			}
		}
	},
	{ immediate: true }
);

watch(
	valorHigienizacaoEsteticaNovo,
	(val) => {
		if (props.type == 'higienizacao') {
			if (val.toString().length > 0) {
				buttonActive.value = true;
			} else {
				buttonActive.value = false;
			}
		}
	},
	{ immediate: true }
);

watch(
	valorProvisaoRevisoesNovo,
	(val) => {
		if (props.type == 'provisaoRevisoes') {
			if (val.toString().length > 0) {
				buttonActive.value = true;
			} else {
				buttonActive.value = false;
			}
		}
	},
	{ immediate: true }
);

watch(
	valorOutrosCustosNovo,
	(val) => {
		if (props.type == 'outrosCustos') {
			if (val == '0') {
				buttonActive.value = false;
			} else {
				buttonActive.value = true;
			}
		}
	},
	{ immediate: true }
);
</script>

<template>
	<div class="modalEditaValorDesk">
		<div class="tituloModal">
			<div class="label">
				<h1>{{ title }}</h1>
			</div>
			<div class="icon" @click="closeModal()">
				<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M6.65685 6.65711L9.88934 9.8896L12.3137 12.314M12.3137 12.314L6.65685 17.9708M12.3137 12.314L17.9706 17.9708M12.3137 12.314L17.9706 6.65711" stroke="#63636E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
				</svg>
			</div>
		</div>
		<div class="track400"></div>
		<div class="contentModal">
			<inputGroup v-if="type == 'valorFinal'" :disabled="false" :placeholder="'R$'" :type="'editValorFinal'" :error="errorValorFinal" :border="true">
				<template #label>Novo valor final</template>
			</inputGroup>
			<inputGroup v-if="type == 'tempoVenda'" :disabled="false" :placeholder="'dias'" :type="'editValorTempoVenda'" :error="errorValorFinal" :border="true">
				<template #label>Novo tempo para venda</template>
			</inputGroup>
			<inputGroup v-if="type == 'mediaInternet'" :disabled="false" :placeholder="'R$'" :type="'editValorMediaInternet'" :error="errorValorFinal" :border="true">
				<template #label>Nova média internet</template>
			</inputGroup>
			<inputGroup v-if="type == 'margemNegociacao'" :disabled="false" :placeholder="'R$'" :type="'editValorMargemNegociacao'" :error="errorValorFinal" :border="true">
				<template #label>Nova margem de negociação</template>
			</inputGroup>
			<inputGroup v-if="type == 'lucro'" :disabled="false" :placeholder="'R$'" :type="'editValorLucro'" :error="errorValorFinal" :border="true">
				<template #label>Novo lucro</template>
			</inputGroup>
			<inputGroup v-if="type == 'reparos'" :disabled="false" :placeholder="'R$'" :type="'editValorReparos'" :error="errorValorFinal" :border="true">
				<template #label>Novo reparo</template>
			</inputGroup>
			<inputGroup v-if="type == 'higienizacao'" :disabled="false" :placeholder="'R$'" :type="'editValorHigienizacao'" :error="errorValorFinal" :border="true">
				<template #label>Nova higienização</template>
			</inputGroup>
			<inputGroup v-if="type == 'provisaoRevisoes'" :disabled="false" :placeholder="'R$'" :type="'editValorProvisaoRevisoes'" :error="errorValorFinal" :border="true">
				<template #label>Nova provisão revisão</template>
			</inputGroup>
			<inputGroup v-if="type == 'outrosCustos'" :disabled="false" :placeholder="'R$'" :type="'editValorOutrosCustos'" :error="errorValorFinal" :border="true">
				<template #label>Novo outros custos</template>
			</inputGroup>
			<div class="error" v-if="errorValorFinal">
				<p>
					{{ errorTextValorFinal }}
				</p>
			</div>
		</div>
		<div class="buttonWrapper">
			<buttonVue v-if="type == 'valorFinal'" :type="buttonActive == true ? 'active' : 'custom'" :action="'saveChangesValorFinal'" :color="'deactive'">
				<template #labelButton>Salvar alterações</template>
			</buttonVue>
			<buttonVue v-if="type !== 'valorFinal'" :type="buttonActive == true ? 'active' : 'custom'" :action="'saveChangesRenegociacao'" :color="'deactive'">
				<template #labelButton>Salvar alterações</template>
			</buttonVue>
		</div>
	</div>
</template>
